<template>
  <!--begin::Tables Widget 9-->
  <KTNewCopyAccountModal
    :mainAccountId="mainAccountId"
    :fetchDataParent="fetchData"
  ></KTNewCopyAccountModal>
  <KTCopyAccountSettingsModal
    :copyAccountId="copyAccountId"
    :copyAccountName="copyAccountName"
    :mainAccountId="mainAccountId"
    :fetchDataParent="fetchData"
  >
  </KTCopyAccountSettingsModal>
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Copy Accounts</span>

        <span class="text-muted mt-1 fw-bold fs-7"
          >These exchange accounts will copy all the orders which are executed
          on the main account, after being activated.
        </span>
      </h3>
      <KTModalCard
        title="New Address Modal Example"
        description="Click on the below buttons to launch <br/>a new address example."
        image="media/illustrations/sketchy-1/19.png"
        button-text="Add New Account"
        modal-id="kt_modal_new_address"
      ></KTModalCard>
      <!--      <div-->
      <!--        class="card-toolbar"-->
      <!--        data-bs-toggle="tooltip"-->
      <!--        data-bs-placement="top"-->
      <!--        data-bs-trigger="hover"-->
      <!--        title="Click to add an account"-->
      <!--      >-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="btn btn-sm btn-light-primary"-->
      <!--          data-bs-toggle="modal"-->
      <!--          data-bs-target="#kt_modal_invite_friends"-->
      <!--          @click="createAccount()"-->
      <!--        >-->
      <!--          <span class="svg-icon svg-icon-3">-->
      <!--            <inline-svg src="media/icons/duotune/arrows/arr075.svg" />-->
      <!--          </span>-->
      <!--          New Account-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="w-25px">
                <div
                  class="
                    form-check form-check-sm form-check-custom form-check-solid
                  "
                >
                  <!--                  <input class="form-check-input" type="checkbox" value="1" />-->
                </div>
              </th>
              <th class="min-w-50px">
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/metronic/releases/2022-07-14-092914/core/html/src/media/icons/duotune/electronics/elc007.svg-->
                <span class="svg-icon svg-icon-muted svg-icon-2hx"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4409 22C13.5455 22 14.4409 21.1046 14.4409 20C14.4409 18.8954 13.5455 18 12.4409 18C11.3364 18 10.4409 18.8954 10.4409 20C10.4409 21.1046 11.3364 22 12.4409 22Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M9.04095 14.8L9.94095 16.1C10.6409 15.6 11.5409 15.3 12.4409 15.3C13.3409 15.3 14.2409 15.6 14.9409 16.1L15.8409 14.8C16.1409 14.3 16.0409 13.6 15.4409 13.4C14.5409 13 13.5409 12.7 12.4409 12.7C11.3409 12.7 10.3409 12.9 9.44095 13.4C8.84095 13.6 8.74095 14.3 9.04095 14.8Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M3.14096 5.80005L4.04095 7.19995C6.44095 5.59995 9.34094 4.69995 12.4409 4.69995C15.5409 4.69995 18.4409 5.59995 20.8409 7.19995L21.7409 5.80005C22.0409 5.30005 21.8409 4.70002 21.3409 4.40002C18.7409 2.90002 15.6409 2 12.4409 2C9.24094 2 6.14095 2.90002 3.54095 4.40002C3.04095 4.70002 2.84096 5.30005 3.14096 5.80005Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M6.14097 10.3L7.04096 11.7C8.64096 10.7 10.441 10.1 12.541 10.1C14.641 10.1 16.441 10.7 18.041 11.7L18.941 10.3C19.241 9.80005 19.141 9.10002 18.541 8.90002C16.741 7.90002 14.741 7.40002 12.541 7.40002C10.341 7.40002 8.34096 7.90002 6.54096 8.90002C5.94096 9.10002 5.74097 9.80005 6.14097 10.3Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <!--end::Svg Icon-->
              </th>
              <th class="min-w-150px">Account name/apikey</th>
              <th
                class="min-w-140px"
                data-bs-placement="top"
                title="This status indicates whether the copy account is in sync with the main account."
                data-bs-toggle="tooltip"
              >
                Synchronized
              </th>
              <th class="min-w-120px">exchange</th>
              <th class="min-w-120px">active</th>
              <th style="min-width: 100px">position (%)</th>
              <th
                class="min-w-200px"
                data-bs-placement="top"
                title="This multiplier is used to determine the copy account trade position. The position size is calculated as ('lot multiplier' * 'master account position'). The multiplier itself is determined by comparing the main account balance with the copy account balance."
                data-bs-toggle="tooltip"
              >
                Lot Multiplier
                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="This multiplier is used to determine the copy account trade position. The position size is calculated as ('lot multiplier' * 'master account position'). The multiplier itself is determined by comparing the main account balance with the copy account balance."
                ></i>
              </th>
              <th style="min-width: 130px" class="text-end">Actions</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in copyAccounts" :key="index">
              <tr>
                <td>
                  <div
                    class="
                      form-check
                      form-check-sm
                      form-check-custom
                      form-check-solid
                    "
                  >
                    <!--                    <input-->
                    <!--                      class="form-check-input widget-9-check"-->
                    <!--                      type="checkbox"-->
                    <!--                      :value="index"-->
                    <!--                    />-->
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <!--                    <div class="symbol symbol-45px me-5">-->
                    <!--                      <img :src="item.image" alt="" />-->
                    <!--                    </div>-->
                    <div class="d-flex justify-content-start flex-column">
                      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/metronic/releases/2022-07-14-092914/core/html/src/media/icons/duotune/abstract/abs036.svg-->
                      <span
                        :class="`
                            svg-icon
                            svg-icon-2
                            svg-icon-${item.websocketConnection}
                            `"
                        data-bs-toggle="tooltip"
                        :title="`${item.websocketConnectionTooltip}`"
                        ><svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22 12C22 12.2 22 12.5 22 12.7L19.5 10.2L16.9 12.8C16.9 12.5 17 12.3 17 12C17 9.5 15.2 7.50001 12.8 7.10001L10.2 4.5L12.7 2C17.9 2.4 22 6.7 22 12ZM11.2 16.9C8.80001 16.5 7 14.5 7 12C7 11.7 7.00001 11.5 7.10001 11.2L4.5 13.8L2 11.3C2 11.5 2 11.8 2 12C2 17.3 6.09999 21.6 11.3 22L13.8 19.5L11.2 16.9Z"
                            fill="currentColor"
                          />
                          <path
                            opacity="0.3"
                            d="M22 12.7C21.6 17.9 17.3 22 12 22C11.8 22 11.5 22 11.3 22L13.8 19.5L11.2 16.9C11.5 16.9 11.7 17 12 17C14.5 17 16.5 15.2 16.9 12.8L19.5 10.2L22 12.7ZM10.2 4.5L12.7 2C12.5 2 12.2 2 12 2C6.7 2 2.4 6.1 2 11.3L4.5 13.8L7.10001 11.2C7.50001 8.8 9.5 7 12 7C12.3 7 12.5 7.00001 12.8 7.10001L10.2 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <!--                    <div class="symbol symbol-45px me-5">-->
                    <!--                      <img :src="item.image" alt="" />-->
                    <!--                    </div>-->
                    <div class="d-flex justify-content-start flex-column">
                      <a class="text-dark fw-bolder text-hover-primary fs-6"
                        >{{ item.name }}
                      </a>

                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.apikey }}</span
                      >
                    </div>
                  </div>
                </td>

                <td>
                  <span
                    :class="`badge badge-${item.inSyncstate}`"
                    data-bs-placement="top"
                    title="This status indicates whether the copy account is in sync with the main account. When out of sync, it will NOT copy any of the main account orders to this account."
                    data-bs-toggle="tooltip"
                    >{{ item.inSyncstatus }}</span
                  >
                </td>

                <td>
                  <a
                    class="text-dark fw-bolder text-hover-primary d-block fs-6"
                    >{{ item.exchange }}</a
                  >
                </td>
                <td>
                  <!--                <span :class="`badge badge-light-${payment.status.state}`">{{-->
                  <!--                    // payment.status.label-->
                  <!--                  }}</span>-->
                  <span
                    data-bs-placement="top"
                    title="Tooltip on top"
                    :class="`badge badge-${item.state}`"
                    data-bs-toggle="tooltip"
                    >{{ item.status }}</span
                  >
                </td>
                <td>
                  <a class="text-dark fw-bolder text-hover-primary fs-6"
                    >{{ item.position }} <br />{{ item.positionPercentage }}%
                  </a>
                </td>
                <td>
                  <a class="text-dark fw-bolder text-hover-primary fs-6">{{
                    item.tradeSizeFactor
                  }}</a>
                </td>

                <td class="text-end">
                  <a
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                  >
                    <span
                      data-bs-placement="top"
                      title="This button allows you to change the lot size factor."
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_copy_account_settings"
                      class="svg-icon svg-icon-3"
                      @click="settings(item)"
                    >
                      <inline-svg
                        src="media/icons/duotune/general/gen008.svg"
                      />
                    </span>
                  </a>
                  <a
                    style="margin-left: 5px"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                  >
                    <span
                      data-bs-placement="top"
                      title="This button allows you to switch the status between active/inactive for syncing the action by the main account to the copy account."
                      data-bs-toggle="tooltip"
                      class="svg-icon svg-icon-3"
                      @click="switchActive(item)"
                    >
                      <inline-svg src="media/icons/duotune/arrows/arr029.svg" />
                    </span>
                  </a>
                  <!--delete-->
                  <a
                    style="margin-left: 5px"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                  >
                    <span
                      class="svg-icon svg-icon-3"
                      data-bs-placement="top"
                      title="Remove the copy account."
                      data-bs-toggle="tooltip"
                      @click="deleteAccount(item, this.mainAccountId)"
                    >
                      <inline-svg
                        src="media/icons/duotune/general/gen027.svg"
                      />
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, Ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import KTModalCard from "@/components/cards/CardCopyAccount.vue";
import KTNewCopyAccountModal from "@/components/modals/forms/NewCopyAccountModal.vue";
import KTCopyAccountSettingsModal from "@/components/modals/forms/CopyAccountSettingsModal.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as ReconnectingWebSocket from "../../../../assets/js/reconnecting-websocket.min.js";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    KTModalCard,
    KTNewCopyAccountModal,
    KTCopyAccountSettingsModal,
  },
  props: {
    widgetClasses: String,
    cardClasses: String,
    mainAccountsId: String,
    mainAccountsId2: String,
    loadCopyAccounts: Function,
  },
  methods: {
    enableOverride() {
      if (this.currentItem.overrideTradeSizeFactor) {
        this.currentItem.overrideTradeSizeFactor = false;
      } else {
        this.currentItem.overrideTradeSizeFactor = true;
      }
    },
    // TODO: check if this code can be removed
    createAccount() {
      // this.$router.push({ path: "/createmainaccount" });
    },
    settings(item) {
      this.copyAccountId = item.id;
      this.copyAccountName = item.name;
      this.currentItem = item;
    },
    fetchData(accountId) {
      this.$store.dispatch(Actions.GET_COPYACCOUNT, accountId).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].active === 1) {
            data.data[i].status = "Active";
            data.data[i].state = "success";
          } else {
            data.data[i].status = "Inactive";
            data.data[i].state = "white";
          }
        }
        this.copyAccounts = data.data;
        this.fetchPositionWebsocket(this.copyAccounts);
      });
    },
    fetchPositionWebsocket(copyAccounts) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      const accessToken = window.localStorage.getItem("id_token");
      for (let i = 0; i < this.copyAccounts.length; i++) {
        const connection = new ReconnectingWebSocket(
          // TODO: create a proper TS file for this
          `wss://api.cryptocopytrade.io/ws/?subaccountid=${copyAccounts[i].id}&accesstoken=${accessToken}`
        );
        let tm;
        const ping = () => {
          connection.send("__ping__");
          tm = setTimeout(function () {
            /// ---connection closed ///
          }, 5000);
        };
        const pong = () => {
          clearTimeout(tm);
        };
        connection.onopen = function (event) {
          setInterval(ping, 10000);
          self.updateCopyAccountFromWebsocket(
            { id: copyAccounts[i].id, connection: "open" },
            "websocket"
          );
        };
        connection.onclose = function (event) {
          self.updateCopyAccountFromWebsocket(
            { id: copyAccounts[i].id, connection: "closed" },
            "websocket"
          );
        };
        connection.onmessage = function (event) {
          // console.log(`event: ${event.data}`);
          if (event.data == "__pong__") {
            pong();
            return;
          }
          const data = JSON.parse(event.data);
          // here it updates keys such as position, tradeSizefactor and others.
          if (data.position) {
            let obj = {
              currentQty: data.position,
              id: copyAccounts[i].id,
            };
            self.updateCopyAccount(obj);
          } else {
            const key = Object.keys(data)[0];
            let obj = {
              [key]: data[key],
              id: copyAccounts[i].id,
            };
            self.updateCopyAccountFromWebsocket(obj, key);
          }
        };
      }
    },
    updateCopyAccountFromWebsocket(copyAccountIdAndQuantity, key) {
      for (let i = 0; i < this.copyAccounts.length; i++) {
        if (this.copyAccounts[i].id == copyAccountIdAndQuantity.id) {
          this.copyAccounts[i][key] = copyAccountIdAndQuantity[key];
          if (key.toString() === "InSync") {
            if (copyAccountIdAndQuantity[key] === "true") {
              this.copyAccounts[i].inSync = true;
              this.copyAccounts[i].inSyncstatus = "In sync";
              this.copyAccounts[i].inSyncstate = "success";
            } else {
              this.copyAccounts[i].inSync = false;
              this.copyAccounts[i].inSyncstatus = "Out of Sync";
              this.copyAccounts[i].inSyncstate = "white";
            }
          }
          if (key.toString() === "websocket") {
            if (copyAccountIdAndQuantity.connection === "closed") {
              this.copyAccounts[i].websocketConnection = "danger";
              this.copyAccounts[i].websocketConnectionTooltip =
                "NOT connected to the CryptoCopyTrade server! Please refresh and try again.";
            } else if (copyAccountIdAndQuantity.connection === "open") {
              this.copyAccounts[i].websocketConnection = "success";
              this.copyAccounts[i].websocketConnectionTooltip =
                "Connected to the CryptoCopyTrade server.";
            } else {
              this.copyAccounts[i].websocketConnection = "warning";
              this.copyAccounts[i].websocketConnectionTooltip =
                "Connecting to the CryptoCopyTrade server.";
            }
          }
        }
      }
    },
    updateCopyAccount(copyAccountIdAndQuantity) {
      for (let i = 0; i < this.copyAccounts.length; i++) {
        if (this.copyAccounts[i].id == copyAccountIdAndQuantity.id) {
          this.copyAccounts[i].position = copyAccountIdAndQuantity.currentQty;
        }
      }
    },
  },
  data() {
    return {
      mainAccountId: this.mainAccountsId,
      copyAccountId: 0,
      copyAccountName: String,
    };
  },
  watch: {
    mainAccountsId: function (newVal, oldVal) {
      this.mainAccountId = newVal;
      if (newVal !== "0") {
        this.fetchData(newVal);
      }
    },
  },
  setup(props) {
    const accountsId = ref(props.mainAccountsId);
    const store = useStore();
    const checked = ref(false);
    const currentItem = ref({ overrideTradeSizeFactor: false });
    interface copyaccount {
      id: string;
      apikey: string;
      secret: string;
      exchange: string;
      name: string;
      active: number;
      status: string;
      state: string;
      position: number;
      inSync: boolean;
      inSyncstate: string;
      inSyncstatus: string;
      tradeSizeFactor: string;
      positionPercentage: string;
      websocketConnection: string;
      websocketConnectionTooltip: string;
    }
    type _copyaccounts = copyaccount[];
    const copyAccounts: Ref<_copyaccounts> = ref([]);
    const childComponentRef = ref();

    // TODO validate if this does anything, else remove
    function settingsFor(item) {
      childComponentRef.value.updateCopyAccountId(item.id);
    }
    const switchActive = async (item) => {
      const data = await store.dispatch(Actions.SET_COPYACCOUNT_ACTIVE, item);
      // update local value
      for (let i = 0; i < copyAccounts.value.length; i++) {
        if (copyAccounts.value[i].id === item.id) {
          // switch active
          if (copyAccounts.value[i].active === 0) {
            copyAccounts.value[i].active = 1;
            copyAccounts.value[i].status = "Active";
            copyAccounts.value[i].state = "success";
          } else {
            copyAccounts.value[i].active = 0;
            copyAccounts.value[i].status = "Inactive";
            copyAccounts.value[i].state = "white";
          }
        }
      }
    };

    const deleteAccount = async (item, mainaccountId) => {
      const result = await Swal.fire({
        title: "Are you sure? This will permanently remove the copyaccount",
        icon: "info",
        confirmButtonText: "Yes",
        showCancelButton: true,
      });

      if (result["isConfirmed"]) {
        const Ids = {
          mainaccountId: mainaccountId,
          subaccountId: item.id,
        };
        await store.dispatch(Actions.DELETE_COPY_ACCOUNT, Ids);
        const response = await store.dispatch(
          Actions.GET_COPYACCOUNT,
          mainaccountId
        );
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].active === 1) {
            response.data[i].status = "Active";
            response.data[i].state = "success";
          } else {
            response.data[i].status = "Inactive";
            response.data[i].state = "white";
          }
        }
        copyAccounts.value = response.data;
      }
    };

    // eslint-disable-next-line no-undef
    // onMounted(() => {
    //   fetchData();
    // });
    let accountsIdValue = JSON.stringify(accountsId);
    return {
      checked,
      deleteAccount,
      copyAccounts,
      accountsIdValue,
      switchActive,
      currentItem,
      childComponentRef,
      settingsFor,
    };
  },
});
</script>

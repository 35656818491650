<template>
  <!--begin::Modal - New Address-->
  <div
    class="modal fade"
    ref="newAddressModalRef"
    id="kt_modal_copy_account_settings"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <Form
          class="form"
          id="kt_modal_new_address_form"
          :validation-schema="validationSchema"
        >
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_new_address_header">
            <!--begin::Modal title-->
            <h2>Settings for "{{ copyAccountName }}"</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7"
              id="kt_modal_new_address_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_new_address_header"
              data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
              data-kt-scroll-offset="300px"
            >
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->

                <label class="required fs-5 fw-bold mb-5"
                  >This multiplier is used to determine the copy account trade
                  position. The position size after copying a trade for this
                  copy account is calculated as ('lot multiplier' * 'master
                  account position'). The multiplier itself is determined by
                  comparing the main account balance with the copy account
                  balance. You can override this value below. Please note
                  setting it too high can result in orders cancelled or account
                  liquidation.</label
                >
                <label class="required fs-5 fw-bold mb-5"
                  ><strong
                    >Lot multiplier: we advise to set this value to
                    {{ tradeSizeFactorOriginalValue }} or disable the override
                    altogether.
                  </strong></label
                >
                <!--begin::Input-->
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <button
                    type="button"
                    :class="`btn btn-${overrideLotMultiplierValueState} me-5`"
                    @click="toggleOverrideLotMultiplier()"
                  >
                    Manually override lot multiplier value?
                    <span class="badge badge-circle badge-warning ms-2">{{
                      overrideLotMultiplierValue
                    }}</span>
                  </button>
                </div>
                <br />
                <Field
                  class="form-control form-control-solid"
                  name="tradeSizeFactor"
                  :disabled="!overrideLotMultiplier"
                  v-model="copyAccountSettings.tradeSizeFactor"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="tradeSizeFactor" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->
                <label
                  style="color: red"
                  class="fs-5 fw-bold mb-5"
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-dark"
                  data-bs-placement="top"
                  data-html="true"
                  title="This multiplier is used to determine the copy account trade position. <br/> The position size is calculated as ('lot multiplier' * 'master account position'). <br/> The multiplier itself is determined by comparing the main account balance with the copy account balance."
                  >Warning: please do not change this value when there is an
                  open position. It will most likely have unexpected side
                  effects.</label
                >
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2"
                  ><strong
                    >Always sync orders no matter the state of the copy
                    account?</strong
                  ></label
                >
                <!--end::Label-->
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <button
                    type="button"
                    :class="`btn btn-${alwaysSyncValueState} me-5`"
                    @click="toggleAlwaysSync()"
                  >
                    Always Sync?
                    <span class="badge badge-circle badge-warning ms-2">{{
                      alwaysSyncValue
                    }}</span>
                  </button>
                </div>
              </div>
              <!--end::Input group-->
              <!--end::Input group-->
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              type="reset"
              id="kt_modal_new_address_cancel"
              class="btn btn-white me-3"
            >
              Discard
            </button>
            <!--end::Button-->

            <!--begin::Button-->
            <button
              ref="submitButtonRef"
              type="button"
              id="kt_modal_new_address_submit"
              class="btn btn-primary"
              @click="submit()"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
    </div>
  </div>
  <!--end::Modal - New Address-->
</template>

<script lang="ts">
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, Ref, ref, watchEffect, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";

interface copyaccount {
  id: string;
  apikey: string;
  secret: string;
  exchange: string;
  name: string;
  active: number;
  status: string;
  state: string;
  position: number;
  inSync: boolean;
  inSyncstate: string;
  inSyncstatus: string;
  tradeSizeFactor: string;
  positionPercentage: string;
}

interface copyaccountSettings {
  tradeSizeFactor: number;
  tradeSizeFactorOverride: string;
  alwaysSync: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      copyAccountIdWatch: this.copyAccountId,
      // eslint-disable-next-line vue/no-reserved-keys
      _tradeSizeFactor: 0,
    };
  },
  watch: {
    mainAccountId: function (newVal, oldVal) {
      this.copyAccountIdWatch = newVal;
      if (newVal !== 0) {
        // this.fetchData(newVal);
        // console.log(`AccountId Value is: ${JSON.stringify(oldVal)} ${JSON.stringify(newVal)}`);
      }
    },
  },
  methods: {},
  props: {
    fetchDataParent: Function,
    copyAccountId: Number,
    copyAccountName: String,
    mainAccountId: String,
  },
  setup(props) {
    type _copyaccounts = copyaccount[];
    const store = useStore();
    let tradeSizeFactor = ref(0);
    let tradeSizeFactorOriginalValue = ref(0);
    let alwaysSync = ref(false);
    let overrideLotMultiplier = ref(false);
    let alwaysSyncValue = ref("OFF");
    let overrideLotMultiplierValue = ref("OFF");
    let alwaysSyncValueState = ref("primary");
    let overrideLotMultiplierValueState = ref("primary");
    const copyAccountId = ref(props.copyAccountId);
    const copyAccountName = ref(props.copyAccountName);
    const copyAccountSettings = ref<copyaccountSettings>({
      tradeSizeFactor: 0,
      tradeSizeFactorOverride: "false",
      alwaysSync: "false",
    });
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);
    watch(
      () => props.copyAccountId,
      (selection, prevSelection) => {
        if (props.copyAccountId !== 0) {
          copyAccountId.value = props.copyAccountId;
          getSettings(props.copyAccountId);
        }
      }
    );
    async function getSettings(copyAccountId) {
      store
        .dispatch(Actions.GET_COPYACCOUNTSETTINGS, copyAccountId)
        .then((data) => {
          tradeSizeFactor.value = data.data.settings.tradeSizeFactorOverride;
          tradeSizeFactorOriginalValue.value =
            data.data.settings.tradeSizeFactor;
          copyAccountSettings.value.tradeSizeFactor =
            data.data.settings.tradeSizeFactorOverrideEnabled === "false"
              ? data.data.settings.tradeSizeFactor
              : data.data.settings.tradeSizeFactorOverride;
          toggleAlwaysSync(data.data.settings.alwaysSync === "true");
          toggleOverrideLotMultiplier(
            data.data.settings.tradeSizeFactorOverrideEnabled === "true"
          );
        });
    }

    async function toggleOverrideLotMultiplier(value) {
      overrideLotMultiplier.value =
        typeof value !== "undefined" ? value : !overrideLotMultiplier.value;
      overrideLotMultiplierValue.value = overrideLotMultiplier.value
        ? "ON"
        : "OFF";
      overrideLotMultiplierValueState.value = overrideLotMultiplier.value
        ? "success"
        : "primary";
    }

    async function toggleAlwaysSync(value) {
      alwaysSync.value =
        typeof value !== "undefined" ? value : !alwaysSync.value;
      alwaysSyncValue.value = alwaysSync.value ? "ON" : "OFF";
      alwaysSyncValueState.value = alwaysSync.value ? "success" : "primary";
    }

    Yup.addMethod(
      Yup.string,
      "TradeSizeFactorValidationDot",
      function (errorMessage) {
        return this.test(`contains .`, errorMessage, function (value) {
          const { path, createError } = this;
          var hasNumber = /\d/;
          return (
            (value &&
              value.includes(".") &&
              !/[a-z]/i.test(value) &&
              value.split(".").length - 1 === 1) ||
            createError({ path, message: errorMessage })
          );
        });
      }
    );

    const validationSchema = Yup.object().shape({
      tradeSizeFactor: Yup.string()
        .required()
        .TradeSizeFactorValidationDot(
          "Lot multiplier requires only numbers and one dot"
        )
        .label("tradeSizeFactor"),
    });

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      await validationSchema.validate({
        tradeSizeFactor: copyAccountSettings.value.tradeSizeFactor,
      });
      console.log(
        `tradeSizeFactor ${copyAccountSettings.value.tradeSizeFactor}`
      );
      const body = {
        alwaysSync: alwaysSync.value,
        tradeSizeFactorOverride: copyAccountSettings.value.tradeSizeFactor,
        overrideLotMultiplier: overrideLotMultiplier.value,
        copyAccountId: copyAccountId.value,
      };
      store
        .dispatch(Actions.UPDATE_COPYACCOUNTSETTINGS, body)
        .then(function (data) {
          let timerInterval;
          let message = "Account settings have been updated...";
          let timer = 3000;
          let ok = true;
          if (data.data === "NOK") {
            message =
              "Input is invalid, please check value for Lot Multiplier again...";
            timer = 8000;
            ok = false;
          }
          if (ok) {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
          }
          Swal.fire({
            title: message,
            html: "I will close in <b></b> milliseconds.",
            timer: timer,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
          }).then(() => {
            if (ok) {
              hideModal(newAddressModalRef.value);
              if (props.fetchDataParent) {
                props.fetchDataParent(props.mainAccountId);
              }
            }
          });
        });
    };

    return {
      validationSchema,
      // submit,
      submitButtonRef,
      newAddressModalRef,
      copyAccountSettings,
      getSettings,
      tradeSizeFactor,
      submit,
      toggleAlwaysSync,
      toggleOverrideLotMultiplier,
      alwaysSync,
      alwaysSyncValue,
      alwaysSyncValueState,
      overrideLotMultiplier,
      overrideLotMultiplierValue,
      overrideLotMultiplierValueState,
      tradeSizeFactorOriginalValue,
    };
  },
});
</script>

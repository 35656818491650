
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";

interface newCopyAccountData {
  name: string;
  apikey: string;
  secret: string;
  exchangename: string;
  accountId: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      mainAccountIdModalData: this.mainAccountId,
    };
  },
  watch: {
    mainAccountId: function (newVal, oldVal) {
      this.mainAccountIdModalData = newVal;
      if (newVal !== 0) {
        // this.fetchData(newVal);
        // console.log(`AccountId Value is: ${JSON.stringify(oldVal)} ${JSON.stringify(newVal)}`);
      }
    },
  },
  methods: {},
  props: { fetchDataParent: Function, mainAccountId: String },
  setup(props) {
    const store = useStore();
    const AccountId = ref(props.mainAccountId);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);
    // const mainAccountId = AccountId;
    // console.log(`mainAccountId Value is: ${_mainAccountId.value}`);

    const newCopyAccountData = ref<newCopyAccountData>({
      name: "",
      apikey: "",
      secret: "",
      exchangename: "",
      accountId: "",
    });

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("name"),
      apikey: Yup.string().required().label("apikey"),
      secret: Yup.string().required().label("secret"),
      exchangename: Yup.string().required().label("exchangename"),
    });

    const submit = async (mainAccountId) => {
      if (!submitButtonRef.value) {
        return;
      }
      let copyAccountData = newCopyAccountData.value;
      const res = await validationSchema.validate({
        name: copyAccountData.name,
        apikey: copyAccountData.apikey,
        secret: copyAccountData.secret,
        exchangename: copyAccountData.exchangename,
      });
      copyAccountData.accountId = mainAccountId;
      store
        .dispatch(Actions.CREATE_COPY_ACCOUNT, copyAccountData)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(function (data) {
          let timerInterval;
          let message = "Account has been added";
          let timer = 3000;
          let ok = true;
          if (data.data === "NOK") {
            message =
              "Account is invalid, please check apikey, secret and/or exchange again...";
            timer = 8000;
            ok = false;
          }
          if (ok) {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
          }
          Swal.fire({
            title: message,
            html: "I will close in <b></b> milliseconds.",
            timer: timer,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
          }).then(() => {
            if (ok) {
              hideModal(newAddressModalRef.value);
              if (props.fetchDataParent) {
                props.fetchDataParent(mainAccountId);
              }
            }
          });
        });
    };

    return {
      AccountId,
      newCopyAccountData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
    };
  },
});

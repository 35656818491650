
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, Ref, ref, watchEffect, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";

interface copyaccount {
  id: string;
  apikey: string;
  secret: string;
  exchange: string;
  name: string;
  active: number;
  status: string;
  state: string;
  position: number;
  inSync: boolean;
  inSyncstate: string;
  inSyncstatus: string;
  tradeSizeFactor: string;
  positionPercentage: string;
}

interface copyaccountSettings {
  tradeSizeFactor: number;
  tradeSizeFactorOverride: string;
  alwaysSync: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      copyAccountIdWatch: this.copyAccountId,
      // eslint-disable-next-line vue/no-reserved-keys
      _tradeSizeFactor: 0,
    };
  },
  watch: {
    mainAccountId: function (newVal, oldVal) {
      this.copyAccountIdWatch = newVal;
      if (newVal !== 0) {
        // this.fetchData(newVal);
        // console.log(`AccountId Value is: ${JSON.stringify(oldVal)} ${JSON.stringify(newVal)}`);
      }
    },
  },
  methods: {},
  props: {
    fetchDataParent: Function,
    copyAccountId: Number,
    copyAccountName: String,
    mainAccountId: String,
  },
  setup(props) {
    type _copyaccounts = copyaccount[];
    const store = useStore();
    let tradeSizeFactor = ref(0);
    let tradeSizeFactorOriginalValue = ref(0);
    let alwaysSync = ref(false);
    let overrideLotMultiplier = ref(false);
    let alwaysSyncValue = ref("OFF");
    let overrideLotMultiplierValue = ref("OFF");
    let alwaysSyncValueState = ref("primary");
    let overrideLotMultiplierValueState = ref("primary");
    const copyAccountId = ref(props.copyAccountId);
    const copyAccountName = ref(props.copyAccountName);
    const copyAccountSettings = ref<copyaccountSettings>({
      tradeSizeFactor: 0,
      tradeSizeFactorOverride: "false",
      alwaysSync: "false",
    });
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);
    watch(
      () => props.copyAccountId,
      (selection, prevSelection) => {
        if (props.copyAccountId !== 0) {
          copyAccountId.value = props.copyAccountId;
          getSettings(props.copyAccountId);
        }
      }
    );
    async function getSettings(copyAccountId) {
      store
        .dispatch(Actions.GET_COPYACCOUNTSETTINGS, copyAccountId)
        .then((data) => {
          tradeSizeFactor.value = data.data.settings.tradeSizeFactorOverride;
          tradeSizeFactorOriginalValue.value =
            data.data.settings.tradeSizeFactor;
          copyAccountSettings.value.tradeSizeFactor =
            data.data.settings.tradeSizeFactorOverrideEnabled === "false"
              ? data.data.settings.tradeSizeFactor
              : data.data.settings.tradeSizeFactorOverride;
          toggleAlwaysSync(data.data.settings.alwaysSync === "true");
          toggleOverrideLotMultiplier(
            data.data.settings.tradeSizeFactorOverrideEnabled === "true"
          );
        });
    }

    async function toggleOverrideLotMultiplier(value) {
      overrideLotMultiplier.value =
        typeof value !== "undefined" ? value : !overrideLotMultiplier.value;
      overrideLotMultiplierValue.value = overrideLotMultiplier.value
        ? "ON"
        : "OFF";
      overrideLotMultiplierValueState.value = overrideLotMultiplier.value
        ? "success"
        : "primary";
    }

    async function toggleAlwaysSync(value) {
      alwaysSync.value =
        typeof value !== "undefined" ? value : !alwaysSync.value;
      alwaysSyncValue.value = alwaysSync.value ? "ON" : "OFF";
      alwaysSyncValueState.value = alwaysSync.value ? "success" : "primary";
    }

    Yup.addMethod(
      Yup.string,
      "TradeSizeFactorValidationDot",
      function (errorMessage) {
        return this.test(`contains .`, errorMessage, function (value) {
          const { path, createError } = this;
          var hasNumber = /\d/;
          return (
            (value &&
              value.includes(".") &&
              !/[a-z]/i.test(value) &&
              value.split(".").length - 1 === 1) ||
            createError({ path, message: errorMessage })
          );
        });
      }
    );

    const validationSchema = Yup.object().shape({
      tradeSizeFactor: Yup.string()
        .required()
        .TradeSizeFactorValidationDot(
          "Lot multiplier requires only numbers and one dot"
        )
        .label("tradeSizeFactor"),
    });

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      await validationSchema.validate({
        tradeSizeFactor: copyAccountSettings.value.tradeSizeFactor,
      });
      console.log(
        `tradeSizeFactor ${copyAccountSettings.value.tradeSizeFactor}`
      );
      const body = {
        alwaysSync: alwaysSync.value,
        tradeSizeFactorOverride: copyAccountSettings.value.tradeSizeFactor,
        overrideLotMultiplier: overrideLotMultiplier.value,
        copyAccountId: copyAccountId.value,
      };
      store
        .dispatch(Actions.UPDATE_COPYACCOUNTSETTINGS, body)
        .then(function (data) {
          let timerInterval;
          let message = "Account settings have been updated...";
          let timer = 3000;
          let ok = true;
          if (data.data === "NOK") {
            message =
              "Input is invalid, please check value for Lot Multiplier again...";
            timer = 8000;
            ok = false;
          }
          if (ok) {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
          }
          Swal.fire({
            title: message,
            html: "I will close in <b></b> milliseconds.",
            timer: timer,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
          }).then(() => {
            if (ok) {
              hideModal(newAddressModalRef.value);
              if (props.fetchDataParent) {
                props.fetchDataParent(props.mainAccountId);
              }
            }
          });
        });
    };

    return {
      validationSchema,
      // submit,
      submitButtonRef,
      newAddressModalRef,
      copyAccountSettings,
      getSettings,
      tradeSizeFactor,
      submit,
      toggleAlwaysSync,
      toggleOverrideLotMultiplier,
      alwaysSync,
      alwaysSyncValue,
      alwaysSyncValueState,
      overrideLotMultiplier,
      overrideLotMultiplierValue,
      overrideLotMultiplierValueState,
      tradeSizeFactorOriginalValue,
    };
  },
});

<template>
  <!--begin::Layout-->
  <div class="d-flex flex-column flex-xxl-row">
    <!--begin::Sidebar-->
    <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <!--begin::Card-->
      <div class="card mb-5 mb-xl-8">
        <!--begin::Card body-->
        <div class="card-body pt-15">
          <!--begin::Summary-->
          <div class="d-flex flex-center flex-column mb-5">
            <!--begin::Info-->
            <div class="d-flex flex-wrap flex-center"></div>
            <!--end::Info-->
          </div>
          <!--end::Summary-->

          <!--begin::Details toggle-->
          <div class="d-flex flex-stack fs-4 py-3">
            <div
              class="fw-bolder rotate collapsible"
              data-bs-toggle="collapse"
              href="#kt_customer_view_details"
              role="button"
              aria-expanded="false"
              aria-controls="kt_customer_view_details"
            >
              Main Account Details:
              <span class="ms-2 rotate-180">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="media/icons/duotune/arrows/arr072.svg" />
                </span>
              </span>
            </div>

            <!--            <span-->
            <!--              data-bs-toggle="tooltip"-->
            <!--              data-bs-trigger="hover"-->
            <!--              title="Edit customer details"-->
            <!--            >-->
            <!--              <a-->
            <!--                href="#"-->
            <!--                class="btn btn-sm btn-light-primary"-->
            <!--                data-bs-toggle="modal"-->
            <!--                data-bs-target="#kt_modal_update_customer"-->
            <!--              >-->
            <!--                Edit-->
            <!--              </a>-->
            <!--            </span>-->
          </div>
          <!--end::Details toggle-->

          <div class="separator separator-dashed my-3"></div>

          <!--begin::Details content-->
          <div id="kt_customer_view_details" class="collapse show">
            <div class="py-5 fs-6">
              <!--begin::Badge-->
              <div class="badge badge-light-info d-inline">Premium user</div>
              <!--begin::Badge-->
              <!--begin::Details item-->
              <div class="fw-bolder mt-5">Account Name</div>
              <div class="text-gray-600">{{ mainAccountDetails.name }}</div>
              <!--begin::Details item-->
              <!--begin::Details item-->
              <div class="fw-bolder mt-5">API Key</div>
              <div class="text-gray-600">{{ mainAccountDetails.apikey }}</div>
              <!--begin::Details item-->
              <!--begin::Details item-->
              <div class="fw-bolder mt-5">Secret</div>
              <div class="text-gray-600">{{ mainAccountDetails.secret }}</div>
              <!--begin::Details item-->
              <!--begin::Details item-->
              <div class="fw-bolder mt-5">Exchange</div>
              <div class="text-gray-600">
                {{ mainAccountDetails.exchangename }}
              </div>
              <div class="fw-bolder mt-5">Position</div>
              <div class="text-gray-600">
                {{ mainAccountDetails.position }}
              </div>
              <div class="fw-bolder mt-5">Position Percentage</div>
              <div class="text-gray-600">
                {{ mainAccountDetails.positionPercentage }}%
              </div>
            </div>
          </div>
          <!--end::Details content-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Sidebar-->

    <!--begin::Content-->
    <div class="flex-lg-row-fluid ms-lg-15">
      <!--begin:::Tabs-->
      <ul
        class="
          nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
          border-0
          fs-4
          fw-bold
          mb-8
        "
      >
        <!--begin:::Tab item-->
        <li class="nav-item">
          <a
            :class="
              activeTabName === 'overview'
                ? 'nav-link text-active-primary pb-4 active'
                : 'nav-link text-active-primary pb-4'
            "
            data-bs-toggle="tab"
            @click="changeTab('overview')"
            >Overview</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="
              activeTabName === 'orders'
                ? 'nav-link text-active-primary pb-4 active'
                : 'nav-link text-active-primary pb-4'
            "
            data-bs-toggle="tab"
            @click="changeTab('orders')"
            >Orders</a
          >
        </li>
        <!--end:::Tab item-->

        <!--        &lt;!&ndash;begin:::Tab item&ndash;&gt;-->
        <!--        <li class="nav-item">-->
        <!--          <a-->
        <!--            class="nav-link text-active-primary pb-4"-->
        <!--            data-bs-toggle="tab"-->
        <!--            href="#kt_customer_view_overview_events_and_logs_tab"-->
        <!--            >Events & Logs</a-->
        <!--          >-->
        <!--        </li>-->
        <!--end:::Tab item-->

        <!--        &lt;!&ndash;begin:::Tab item&ndash;&gt;-->
        <!--        <li class="nav-item">-->
        <!--          <a-->
        <!--            class="nav-link text-active-primary pb-4"-->
        <!--            data-kt-countup-tabs="true"-->
        <!--            data-bs-toggle="tab"-->
        <!--            href="#kt_customer_view_overview_statements"-->
        <!--            >Statements</a-->
        <!--          >-->
        <!--        </li>-->
        <!--end:::Tab item-->

        <!--begin:::Tab item-->
        <!--        <li class="nav-item ms-auto">-->
        <!--          &lt;!&ndash;begin::Action menu&ndash;&gt;-->
        <!--          <a-->
        <!--            href="#"-->
        <!--            class="btn btn-primary ps-7"-->
        <!--            data-kt-menu-trigger="click"-->
        <!--            data-kt-menu-attach="parent"-->
        <!--            data-kt-menu-placement="bottom-end"-->
        <!--          >-->
        <!--&lt;!&ndash;            Actions&ndash;&gt;-->
        <!--&lt;!&ndash;            <span class="svg-icon svg-icon-2 me-0">&ndash;&gt;-->
        <!--&lt;!&ndash;              <inline-svg src="media/icons/duotune/arrows/arr072.svg" />&ndash;&gt;-->
        <!--&lt;!&ndash;            </span>&ndash;&gt;-->
        <!--          </a>-->
        <!--          <Dropdown3></Dropdown3>-->
        <!--          &lt;!&ndash;end::Menu&ndash;&gt;-->
        <!--        </li>-->
        <!--end:::Tab item-->
      </ul>
      <!--end:::Tabs-->

      <!--begin:::Tab content overview-->
      <div
        class="tab-content"
        id="myTabContent"
        v-show="activeTabName === 'overview'"
      >
        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade show active"
          id="kt_customer_view_overview_tab"
          role="tabpanel"
        >
          <tableCopyAccounts
            card-classes="mb-6 mb-xl-9"
            :mainAccountsId="mainAccountDetails.id"
            mainAccountsId2="2"
            :method="parentMethod"
          ></tableCopyAccounts>
        </div>
        <!--end:::Tab pane-->

        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade"
          id="kt_customer_view_overview_events_and_logs_tab"
          role="tabpanel"
        >
          <Logs card-classes="mb-6 mb-xl-9"></Logs>
          <Events card-classes="mb-6 mb-xl-9"></Events>
        </div>
        <!--end:::Tab pane-->

        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade"
          id="kt_customer_view_overview_statements"
          role="tabpanel"
        >
          <Earnings card-classes="mb-6 mb-xl-9"></Earnings>
          <Statement card-classes="mb-6 mb-xl-9"></Statement>
        </div>
        <!--end:::Tab pane-->
      </div>
      <!--end:::Tab content-->
      <!--begin:::Tab content orders-->
      <div
        class="tab-content"
        id="myTabContent"
        v-show="activeTabName === 'orders'"
      >
        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade show active"
          id="kt_customer_view_overview_tab"
          role="tabpanel"
        >
          <tableOrders
            card-classes="mb-6 mb-xl-9"
            :mainAccountsId="mainAccountDetails.id"
            mainAccountsId2="2"
            :method="parentMethod"
          ></tableOrders>
        </div>
        <!--end:::Tab pane-->

        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade"
          id="kt_customer_view_overview_events_and_logs_tab"
          role="tabpanel"
        >
          <Logs card-classes="mb-6 mb-xl-9"></Logs>
          <Events card-classes="mb-6 mb-xl-9"></Events>
        </div>
        <!--end:::Tab pane-->

        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade"
          id="kt_customer_view_overview_statements"
          role="tabpanel"
        >
          <Earnings card-classes="mb-6 mb-xl-9"></Earnings>
          <Statement card-classes="mb-6 mb-xl-9"></Statement>
        </div>
        <!--end:::Tab pane-->
      </div>
      <!--end:::Tab content-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Layout-->

  <NewCardModal></NewCardModal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import tableCopyAccounts from "@/components/widgets/tables/tradecopy/tableSubAccountOverview.vue";
import tableOrders from "@/components/widgets/tables/tradecopy/tableOrders.vue";

import { useRoute } from "vue-router";
import { computed } from "vue";

// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import Events from "@/components/customers/cards/events-and-logs/Events.vue";
import Logs from "@/components/customers/cards/events-and-logs/Logs.vue";
import Earnings from "@/components/customers/cards/statments/Earnings.vue";
import Statement from "@/components/customers/cards/statments/Statement.vue";

export default defineComponent({
  name: "account-dashboard",
  components: {
    tableCopyAccounts,
    tableOrders,
    Events,
    Logs,
    Earnings,
    Statement,
    // Dropdown3,
    NewCardModal,
  },
  setup() {
    const store = useStore();
    let activeTabName = ref("overview");
    const mainAccountDetails = ref({
      name: "",
      apikey: "",
      secret: "",
      exchangename: "",
      id: 0,
      position: "",
      positionPercentage: "",
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });

    const route = useRoute();
    const path = computed(() => route.path);
    const mainAccountId = path.value.split("/")[2];

    function fetchAccountDetails() {
      store
        .dispatch(Actions.GET_ACCOUNT_DETAILS, mainAccountId)
        .then((data) => {
          mainAccountDetails.value = data.data[0];
          // also load the websocket
          fetchMainAccountPositionWebsocket(mainAccountId);
          // this.fetchMainAccountPositionWebsocket(newVal);
          // console.log(`mainAccountDetais: ${mainAccountDetails.value.id}`);
        });
    }

    function changeTab(tabName) {
      activeTabName.value = tabName;
    }

    function fetchMainAccountPositionWebsocket(mainAccountId) {
      const accessToken = window.localStorage.getItem("id_token");
      const connection = new WebSocket(
        // TODO: create a proper TS file for this
        `wss://api.cryptocopytrade.io/ws/?mainaccountid=${mainAccountId}&accesstoken=${accessToken}`
      );
      connection.onmessage = function (event) {
        // console.log(`event: ${event.data}`);
        const data = JSON.parse(event.data);
        if (data.position) {
          mainAccountDetails.value.position = data.position;
        } else if (data.positionPercentage || data.positionPercentage === 0) {
          mainAccountDetails.value.positionPercentage = data.positionPercentage;
        }
        // if (data.position) {
        //   let obj = {
        //     currentQty: data.position,
        //     id: copyAccounts[i].id,
        //   };
        //   console.log(obj);
        //   self.updateCopyAccount(obj);
        // } else {
        //   const key = Object.keys(data)[0];
        //   console.log(`object keys: ${JSON.stringify(Object.keys(data))}`);
        //   let obj = {
        //     [key]: data[key],
        //     id: copyAccounts[i].id,
        //   };
        //   console.log(obj);
        //   self.updateCopyAccountGeneric(obj, key);
        // }
        // let currentQty = JSON.parse(event.data)[0].currentQty;
        // let obj = {
        //   currentQty: currentQty,
        //   id: copyAccounts[i].id,
        // };
        // console.log(obj);
        // self.updateCopyAccount(obj);
      };
    }

    onMounted(() => {
      fetchAccountDetails();
    });

    return {
      mainAccountDetails,
      changeTab,
      activeTabName,
    };
  },
});
</script>


import { defineComponent, ref, onMounted, Ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import KTModalCard from "@/components/cards/CardCopyAccount.vue";
import KTNewCopyAccountModal from "@/components/modals/forms/NewCopyAccountModal.vue";
import KTCopyAccountSettingsModal from "@/components/modals/forms/CopyAccountSettingsModal.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as ReconnectingWebSocket from "../../../../assets/js/reconnecting-websocket.min.js";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    KTModalCard,
    KTNewCopyAccountModal,
    KTCopyAccountSettingsModal,
  },
  props: {
    widgetClasses: String,
    cardClasses: String,
    mainAccountsId: String,
    mainAccountsId2: String,
    loadCopyAccounts: Function,
  },
  methods: {
    enableOverride() {
      if (this.currentItem.overrideTradeSizeFactor) {
        this.currentItem.overrideTradeSizeFactor = false;
      } else {
        this.currentItem.overrideTradeSizeFactor = true;
      }
    },
    // TODO: check if this code can be removed
    createAccount() {
      // this.$router.push({ path: "/createmainaccount" });
    },
    settings(item) {
      this.copyAccountId = item.id;
      this.copyAccountName = item.name;
      this.currentItem = item;
    },
    fetchData(accountId) {
      this.$store.dispatch(Actions.GET_COPYACCOUNT, accountId).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].active === 1) {
            data.data[i].status = "Active";
            data.data[i].state = "success";
          } else {
            data.data[i].status = "Inactive";
            data.data[i].state = "white";
          }
        }
        this.copyAccounts = data.data;
        this.fetchPositionWebsocket(this.copyAccounts);
      });
    },
    fetchPositionWebsocket(copyAccounts) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      const accessToken = window.localStorage.getItem("id_token");
      for (let i = 0; i < this.copyAccounts.length; i++) {
        const connection = new ReconnectingWebSocket(
          // TODO: create a proper TS file for this
          `wss://api.cryptocopytrade.io/ws/?subaccountid=${copyAccounts[i].id}&accesstoken=${accessToken}`
        );
        let tm;
        const ping = () => {
          connection.send("__ping__");
          tm = setTimeout(function () {
            /// ---connection closed ///
          }, 5000);
        };
        const pong = () => {
          clearTimeout(tm);
        };
        connection.onopen = function (event) {
          setInterval(ping, 10000);
          self.updateCopyAccountFromWebsocket(
            { id: copyAccounts[i].id, connection: "open" },
            "websocket"
          );
        };
        connection.onclose = function (event) {
          self.updateCopyAccountFromWebsocket(
            { id: copyAccounts[i].id, connection: "closed" },
            "websocket"
          );
        };
        connection.onmessage = function (event) {
          // console.log(`event: ${event.data}`);
          if (event.data == "__pong__") {
            pong();
            return;
          }
          const data = JSON.parse(event.data);
          // here it updates keys such as position, tradeSizefactor and others.
          if (data.position) {
            let obj = {
              currentQty: data.position,
              id: copyAccounts[i].id,
            };
            self.updateCopyAccount(obj);
          } else {
            const key = Object.keys(data)[0];
            let obj = {
              [key]: data[key],
              id: copyAccounts[i].id,
            };
            self.updateCopyAccountFromWebsocket(obj, key);
          }
        };
      }
    },
    updateCopyAccountFromWebsocket(copyAccountIdAndQuantity, key) {
      for (let i = 0; i < this.copyAccounts.length; i++) {
        if (this.copyAccounts[i].id == copyAccountIdAndQuantity.id) {
          this.copyAccounts[i][key] = copyAccountIdAndQuantity[key];
          if (key.toString() === "InSync") {
            if (copyAccountIdAndQuantity[key] === "true") {
              this.copyAccounts[i].inSync = true;
              this.copyAccounts[i].inSyncstatus = "In sync";
              this.copyAccounts[i].inSyncstate = "success";
            } else {
              this.copyAccounts[i].inSync = false;
              this.copyAccounts[i].inSyncstatus = "Out of Sync";
              this.copyAccounts[i].inSyncstate = "white";
            }
          }
          if (key.toString() === "websocket") {
            if (copyAccountIdAndQuantity.connection === "closed") {
              this.copyAccounts[i].websocketConnection = "danger";
              this.copyAccounts[i].websocketConnectionTooltip =
                "NOT connected to the CryptoCopyTrade server! Please refresh and try again.";
            } else if (copyAccountIdAndQuantity.connection === "open") {
              this.copyAccounts[i].websocketConnection = "success";
              this.copyAccounts[i].websocketConnectionTooltip =
                "Connected to the CryptoCopyTrade server.";
            } else {
              this.copyAccounts[i].websocketConnection = "warning";
              this.copyAccounts[i].websocketConnectionTooltip =
                "Connecting to the CryptoCopyTrade server.";
            }
          }
        }
      }
    },
    updateCopyAccount(copyAccountIdAndQuantity) {
      for (let i = 0; i < this.copyAccounts.length; i++) {
        if (this.copyAccounts[i].id == copyAccountIdAndQuantity.id) {
          this.copyAccounts[i].position = copyAccountIdAndQuantity.currentQty;
        }
      }
    },
  },
  data() {
    return {
      mainAccountId: this.mainAccountsId,
      copyAccountId: 0,
      copyAccountName: String,
    };
  },
  watch: {
    mainAccountsId: function (newVal, oldVal) {
      this.mainAccountId = newVal;
      if (newVal !== "0") {
        this.fetchData(newVal);
      }
    },
  },
  setup(props) {
    const accountsId = ref(props.mainAccountsId);
    const store = useStore();
    const checked = ref(false);
    const currentItem = ref({ overrideTradeSizeFactor: false });
    interface copyaccount {
      id: string;
      apikey: string;
      secret: string;
      exchange: string;
      name: string;
      active: number;
      status: string;
      state: string;
      position: number;
      inSync: boolean;
      inSyncstate: string;
      inSyncstatus: string;
      tradeSizeFactor: string;
      positionPercentage: string;
      websocketConnection: string;
      websocketConnectionTooltip: string;
    }
    type _copyaccounts = copyaccount[];
    const copyAccounts: Ref<_copyaccounts> = ref([]);
    const childComponentRef = ref();

    // TODO validate if this does anything, else remove
    function settingsFor(item) {
      childComponentRef.value.updateCopyAccountId(item.id);
    }
    const switchActive = async (item) => {
      const data = await store.dispatch(Actions.SET_COPYACCOUNT_ACTIVE, item);
      // update local value
      for (let i = 0; i < copyAccounts.value.length; i++) {
        if (copyAccounts.value[i].id === item.id) {
          // switch active
          if (copyAccounts.value[i].active === 0) {
            copyAccounts.value[i].active = 1;
            copyAccounts.value[i].status = "Active";
            copyAccounts.value[i].state = "success";
          } else {
            copyAccounts.value[i].active = 0;
            copyAccounts.value[i].status = "Inactive";
            copyAccounts.value[i].state = "white";
          }
        }
      }
    };

    const deleteAccount = async (item, mainaccountId) => {
      const result = await Swal.fire({
        title: "Are you sure? This will permanently remove the copyaccount",
        icon: "info",
        confirmButtonText: "Yes",
        showCancelButton: true,
      });

      if (result["isConfirmed"]) {
        const Ids = {
          mainaccountId: mainaccountId,
          subaccountId: item.id,
        };
        await store.dispatch(Actions.DELETE_COPY_ACCOUNT, Ids);
        const response = await store.dispatch(
          Actions.GET_COPYACCOUNT,
          mainaccountId
        );
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].active === 1) {
            response.data[i].status = "Active";
            response.data[i].state = "success";
          } else {
            response.data[i].status = "Inactive";
            response.data[i].state = "white";
          }
        }
        copyAccounts.value = response.data;
      }
    };

    // eslint-disable-next-line no-undef
    // onMounted(() => {
    //   fetchData();
    // });
    let accountsIdValue = JSON.stringify(accountsId);
    return {
      checked,
      deleteAccount,
      copyAccounts,
      accountsIdValue,
      switchActive,
      currentItem,
      childComponentRef,
      settingsFor,
    };
  },
});

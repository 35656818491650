<template>
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Orders</span>

        <span class="text-muted mt-1 fw-bold fs-7"
          >These exchange accounts will copy all the orders which are executed
          on the main account, after being activated.
        </span>
      </h3>
      <KTModalCard
        title="New Address Modal Example"
        description="Click on the below buttons to launch <br/>a new address example."
        image="media/illustrations/sketchy-1/19.png"
        button-text="Add New Account"
        modal-id="kt_modal_new_address"
      ></KTModalCard>
      <!--      <div-->
      <!--        class="card-toolbar"-->
      <!--        data-bs-toggle="tooltip"-->
      <!--        data-bs-placement="top"-->
      <!--        data-bs-trigger="hover"-->
      <!--        title="Click to add an account"-->
      <!--      >-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="btn btn-sm btn-light-primary"-->
      <!--          data-bs-toggle="modal"-->
      <!--          data-bs-target="#kt_modal_invite_friends"-->
      <!--          @click="createAccount()"-->
      <!--        >-->
      <!--          <span class="svg-icon svg-icon-3">-->
      <!--            <inline-svg src="media/icons/duotune/arrows/arr075.svg" />-->
      <!--          </span>-->
      <!--          New Account-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="w-25px">
                <div
                  class="
                    form-check form-check-sm form-check-custom form-check-solid
                  "
                >
                  <!--                  <input class="form-check-input" type="checkbox" value="1" />-->
                </div>
              </th>
              <th class="min-w-150px">id</th>
              <th class="min-w-120px">status (master account)</th>
              <th class="min-w-120px">status (copy account)</th>
              <th class="min-w-120px">price</th>
              <th class="min-w-150px">quantity</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in orders" :key="index">
              <tr>
                <td>
                  <div
                    class="
                      form-check
                      form-check-sm
                      form-check-custom
                      form-check-solid
                    "
                  >
                    <!--                    <input-->
                    <!--                      class="form-check-input widget-9-check"-->
                    <!--                      type="checkbox"-->
                    <!--                      :value="index"-->
                    <!--                    />-->
                  </div>
                </td>

                <td>
                  <div class="d-flex align-items-center">
                    <!--                    <div class="symbol symbol-45px me-5">-->
                    <!--                      <img :src="item.image" alt="" />-->
                    <!--                    </div>-->
                    <div class="d-flex justify-content-start flex-column">
                      <a class="text-dark fw-bolder text-hover-primary fs-6">{{
                        item.id
                      }}</a>

                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.masterOrderStatus }}</span
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    class="text-dark fw-bolder text-hover-primary d-block fs-6"
                    >{{ item.masterOrderStatus }}</a
                  >
                </td>
                <td>
                  <!--                <span :class="`badge badge-light-${payment.status.state}`">{{-->
                  <!--                    // payment.status.label-->
                  <!--                  }}</span>-->
                  <span
                    data-bs-placement="top"
                    title="Tooltip on top"
                    :class="`badge badge-${item.state}`"
                    data-bs-toggle="tooltip"
                    >{{ item.slaveOrderStatus }}</span
                  >
                </td>
                <td>
                  <a class="text-dark fw-bolder text-hover-primary fs-6">{{
                    item.price
                  }}</a>
                </td>
                <td>
                  <a class="text-dark fw-bolder text-hover-primary fs-6">{{
                    item.quantity
                  }}</a>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-widget-9",
  props: {
    widgetClasses: String,
    cardClasses: String,
    mainAccountsId: String,
    mainAccountsId2: String,
    loadCopyAccounts: Function,
  },
  methods: {
    fetchData(accountId) {
      this.$store.dispatch(Actions.GET_ORDERS, accountId).then((data) => {
        this.orders = data.data;
      });
    },
  },
  data() {
    return {
      mainAccountId: this.mainAccountsId,
      copyAccountId: 0,
      copyAccountName: String,
    };
  },
  watch: {
    mainAccountsId: function (newVal, oldVal) {
      this.mainAccountId = newVal;
      if (newVal !== "0") {
        this.fetchData(newVal);
      }
    },
  },
  setup(props) {
    const accountsId = ref(props.mainAccountsId);
    const store = useStore();
    const orders = ref([]);
    const currentItem = ref({ overrideTradeSizeFactor: false });

    function fetchData() {
      store.dispatch(Actions.GET_ORDERS, accountsId).then((data) => {
        orders.value = data.data;
      });
    }

    let accountsIdValue = JSON.stringify(accountsId);
    return {
      orders,
      accountsIdValue,
      currentItem,
    };
  },
});
</script>

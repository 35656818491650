
import { defineComponent, ref, Ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-widget-9",
  props: {
    widgetClasses: String,
    cardClasses: String,
    mainAccountsId: String,
    mainAccountsId2: String,
    loadCopyAccounts: Function,
  },
  methods: {
    fetchData(accountId) {
      this.$store.dispatch(Actions.GET_ORDERS, accountId).then((data) => {
        this.orders = data.data;
      });
    },
  },
  data() {
    return {
      mainAccountId: this.mainAccountsId,
      copyAccountId: 0,
      copyAccountName: String,
    };
  },
  watch: {
    mainAccountsId: function (newVal, oldVal) {
      this.mainAccountId = newVal;
      if (newVal !== "0") {
        this.fetchData(newVal);
      }
    },
  },
  setup(props) {
    const accountsId = ref(props.mainAccountsId);
    const store = useStore();
    const orders = ref([]);
    const currentItem = ref({ overrideTradeSizeFactor: false });

    function fetchData() {
      store.dispatch(Actions.GET_ORDERS, accountsId).then((data) => {
        orders.value = data.data;
      });
    }

    let accountsIdValue = JSON.stringify(accountsId);
    return {
      orders,
      accountsIdValue,
      currentItem,
    };
  },
});

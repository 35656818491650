
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import tableCopyAccounts from "@/components/widgets/tables/tradecopy/tableSubAccountOverview.vue";
import tableOrders from "@/components/widgets/tables/tradecopy/tableOrders.vue";

import { useRoute } from "vue-router";
import { computed } from "vue";

// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import Events from "@/components/customers/cards/events-and-logs/Events.vue";
import Logs from "@/components/customers/cards/events-and-logs/Logs.vue";
import Earnings from "@/components/customers/cards/statments/Earnings.vue";
import Statement from "@/components/customers/cards/statments/Statement.vue";

export default defineComponent({
  name: "account-dashboard",
  components: {
    tableCopyAccounts,
    tableOrders,
    Events,
    Logs,
    Earnings,
    Statement,
    // Dropdown3,
    NewCardModal,
  },
  setup() {
    const store = useStore();
    let activeTabName = ref("overview");
    const mainAccountDetails = ref({
      name: "",
      apikey: "",
      secret: "",
      exchangename: "",
      id: 0,
      position: "",
      positionPercentage: "",
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });

    const route = useRoute();
    const path = computed(() => route.path);
    const mainAccountId = path.value.split("/")[2];

    function fetchAccountDetails() {
      store
        .dispatch(Actions.GET_ACCOUNT_DETAILS, mainAccountId)
        .then((data) => {
          mainAccountDetails.value = data.data[0];
          // also load the websocket
          fetchMainAccountPositionWebsocket(mainAccountId);
          // this.fetchMainAccountPositionWebsocket(newVal);
          // console.log(`mainAccountDetais: ${mainAccountDetails.value.id}`);
        });
    }

    function changeTab(tabName) {
      activeTabName.value = tabName;
    }

    function fetchMainAccountPositionWebsocket(mainAccountId) {
      const accessToken = window.localStorage.getItem("id_token");
      const connection = new WebSocket(
        // TODO: create a proper TS file for this
        `wss://api.cryptocopytrade.io/ws/?mainaccountid=${mainAccountId}&accesstoken=${accessToken}`
      );
      connection.onmessage = function (event) {
        // console.log(`event: ${event.data}`);
        const data = JSON.parse(event.data);
        if (data.position) {
          mainAccountDetails.value.position = data.position;
        } else if (data.positionPercentage || data.positionPercentage === 0) {
          mainAccountDetails.value.positionPercentage = data.positionPercentage;
        }
        // if (data.position) {
        //   let obj = {
        //     currentQty: data.position,
        //     id: copyAccounts[i].id,
        //   };
        //   console.log(obj);
        //   self.updateCopyAccount(obj);
        // } else {
        //   const key = Object.keys(data)[0];
        //   console.log(`object keys: ${JSON.stringify(Object.keys(data))}`);
        //   let obj = {
        //     [key]: data[key],
        //     id: copyAccounts[i].id,
        //   };
        //   console.log(obj);
        //   self.updateCopyAccountGeneric(obj, key);
        // }
        // let currentQty = JSON.parse(event.data)[0].currentQty;
        // let obj = {
        //   currentQty: currentQty,
        //   id: copyAccounts[i].id,
        // };
        // console.log(obj);
        // self.updateCopyAccount(obj);
      };
    }

    onMounted(() => {
      fetchAccountDetails();
    });

    return {
      mainAccountDetails,
      changeTab,
      activeTabName,
    };
  },
});
